// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
}

.App {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.filterContainer {
  margin: 20px 0;
}

.pageContainer {
  padding: 20px;
}

.productForm {
  width: 300px;
}

.categoryPage {
  justify-content: center;
  display: flex;
}

.productPage {
  display: flex;
  justify-content: space-evenly;
}
.productContainer {
  overflow: hidden;
  border-radius: 5px;
  margin: 10px;
  width: 400px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
img {
  width: 200px;
}

.productDetail {
  padding: 10px;
}

.categoryHolder {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.categoryHolder > p {
  margin: 10px;
}

.btnContainer {
  width: 100px;
  display: flex;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,6BAA6B;AAC/B;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb;uCACqC;AACvC;AACA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,8BAA8B;AAChC","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n}\n\n.App {\n  margin: 0 10px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.filterContainer {\n  margin: 20px 0;\n}\n\n.pageContainer {\n  padding: 20px;\n}\n\n.productForm {\n  width: 300px;\n}\n\n.categoryPage {\n  justify-content: center;\n  display: flex;\n}\n\n.productPage {\n  display: flex;\n  justify-content: space-evenly;\n}\n.productContainer {\n  overflow: hidden;\n  border-radius: 5px;\n  margin: 10px;\n  width: 400px;\n  display: flex;\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,\n    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;\n}\nimg {\n  width: 200px;\n}\n\n.productDetail {\n  padding: 10px;\n}\n\n.categoryHolder {\n  display: flex;\n  justify-content: space-between;\n  margin: 10px;\n}\n\n.categoryHolder > p {\n  margin: 10px;\n}\n\n.btnContainer {\n  width: 100px;\n  display: flex;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
